import React from 'react';

const OurStory = () => {
  return (
    <section
      id="story"
      className="py-16 px-4 lg:px-24 flex flex-col lg:flex-row items-center justify-center"
      style={{ backgroundColor: '#e7d4b4', color: '#142d51' }}
    >
      {/* Left Side */}
      <div className="lg:w-1/2 w-full mb-12 lg:mb-0 text-center lg:text-left">
        <h2 className="text-4xl font-bold mb-4">Our Story</h2>
        <h3 className="text-2xl font-semibold mb-4">Get to Know Us</h3>
        <p className="text-lg leading-relaxed">
          Our story began as four college friends conversing with friends and students who worked as part-time employees. We learned how frustrating it could be when their shifts clashed with personal commitments like school or family events. On the other hand, speaking with restaurant owners in our network revealed just how daunting it was to balance employee availability and unpredictable foot traffic.
        </p>
        <p className="text-lg leading-relaxed mt-4">
          Discovering quickly that managing staffing and day-to-day restaurant operations is time-consuming and overwhelming for managers and employees. We found that existing technologies for this problem tend to be more manual and administrative, adding to the burden.
        </p>
        <p className="text-lg leading-relaxed mt-4">
          That's why we're developing Perks — an AI platform designed to automate staffing and effectively manage and track sales, labor costs, and the cost of commodities handled effortlessly, with just a click of a button.
        </p>
      </div>

      {/* Right Side (Optional Image)
      <div className="lg:w-1/2 w-full flex items-center justify-center">
        <img
          src="/path/to/your/image.jpg"
          alt="Our Story"
          className="w-full h-auto object-cover rounded-lg shadow-lg"
        />
      </div> */}
    </section>
  );
};

export default OurStory;
