import React from 'react';
import logo from '../assets/perkslogo.jpeg'; // Adjust the path to where your logo is stored

const Navbar = () => {
  const scrollToSection = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <nav className="bg-white shadow-md">
      <div className="container mx-auto p-4 flex justify-between items-center">
        {/* Logo and Perks Text */}
        <div className="flex items-center space-x-2 cursor-pointer" onClick={scrollToTop}>
          <img src={logo} alt="Perks Logo" className="w-9 h-9" /> {/* Logo Image */}
          <h1 className="text-2xl font-bold">Perks</h1> {/* Perks Text */}
        </div>
        <ul className="flex space-x-8">
          <li><button onClick={() => scrollToSection('story')}>Our Story</button></li>
          <li><button onClick={() => scrollToSection('team')}>Team</button></li>
          <li><button onClick={() => scrollToSection('contact')}>Contact Us</button></li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
