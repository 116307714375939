import React from 'react';
import Navbar from './components/Navbar';
import OurStory from './components/OurStory';
import Team from './components/Team';
import ContactUs from './components/ContactUs';

function App() {
  return (
    <div>
      <Navbar />
      <OurStory />
      <Team />
      <ContactUs />
    </div>
  );
}

export default App;

