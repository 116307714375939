// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC3-xGYIWyd0r3EzPK-_0ROrplJtl5CKOo",
  authDomain: "perks-web-add3c.firebaseapp.com",
  projectId: "perks-web-add3c",
  storageBucket: "perks-web-add3c.appspot.com",
  messagingSenderId: "737401755522",
  appId: "1:737401755522:web:a741b642f279e125d2e19b",
  measurementId: "G-0P8SPGY664"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app)

export { db, analytics };

