import React, { useState } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../firebaseConfig';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    restaurantName: '',
    title: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (Object.values(formData).some((field) => field === '')) {
      alert('Please fill in all required fields.');
      return;
    }

    try {
      await addDoc(collection(db, 'contacts'), formData);
      alert('Form submitted successfully!');
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        restaurantName: '',
        title: ''
      });
    } catch (error) {
      console.error('Error submitting form: ', error);
      alert('Error submitting form.');
    }
  };

  return (
    <section id="contact" className="h-screen bg-[#142d51] text-[#e7d4b4] py-12">
      <div className="container mx-auto max-w-lg p-8 bg-[#1f3a73] shadow-lg rounded-lg">
        <h2 className="text-4xl font-bold mb-4 text-center">Contact Us</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="firstName" className="block">First Name *</label>
            <input
              id="firstName"
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              placeholder="Enter your first name"
              className="w-full p-2 border border-gray-400 rounded-lg text-black"
              style={{ backgroundColor: '#e7d4b4' }}
              required
            />
          </div>
          <div>
            <label htmlFor="lastName" className="block">Last Name *</label>
            <input
              id="lastName"
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              placeholder="Enter your last name"
              className="w-full p-2 border border-gray-400 rounded-lg text-black"
              style={{ backgroundColor: '#e7d4b4' }}
              required
            />
          </div>
          <div>
            <label htmlFor="email" className="block">Email *</label>
            <input
              id="email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter your email"
              className="w-full p-2 border border-gray-400 rounded-lg text-black"
              style={{ backgroundColor: '#e7d4b4' }}
              required
            />
          </div>
          <div>
            <label htmlFor="phone" className="block">Phone *</label>
            <input
              id="phone"
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Enter your phone number"
              className="w-full p-2 border border-gray-400 rounded-lg text-black"
              style={{ backgroundColor: '#e7d4b4' }}
              required
            />
          </div>
          <div>
            <label htmlFor="restaurantName" className="block">Restaurant Name *</label>
            <input
              id="restaurantName"
              type="text"
              name="restaurantName"
              value={formData.restaurantName}
              onChange={handleChange}
              placeholder="Enter your restaurant name"
              className="w-full p-2 border border-gray-400 rounded-lg text-black"
              style={{ backgroundColor: '#e7d4b4' }}
              required
            />
          </div>
          <div>
            <label htmlFor="title" className="block">Title *</label>
            <input
              id="title"
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              placeholder="Enter your title"
              className="w-full p-2 border border-gray-400 rounded-lg text-black"
              style={{ backgroundColor: '#e7d4b4' }}
              required
            />
          </div>
          <button 
            type="submit" 
            className="w-full p-2 bg-[#e7d4b4] text-[#142d51] font-bold rounded-lg hover:bg-[#d4b89e] transition-all"
          >
            Submit
          </button>
        </form>
      </div>
    </section>
  );
};

export default ContactUs;
