import React from 'react';
import shrenikImg from '../assets/shrenik.jpeg';
import sevaanImg from '../assets/sevaan.jpeg';
import madhanImg from '../assets/madhan.jpeg';
import sumanthImg from '../assets/sumanth.jpeg';

const Team = () => {
  return (
    <section id="team" className="py-12" style={{ backgroundColor: '#142d51', color: '#e7d4b4' }}>
      <h2 className="text-4xl font-bold text-center mb-6">Our Team</h2>
      <p className="text-center text-lg max-w-3xl mx-auto mb-12">
        Here's our amazing Executive Leadership Team at Perks. We are thrilled to introduce the incredibly talented individuals who steer our vision and strategy to build capabilities that will solve problems for restaurants.
      </p>
      <div className="flex flex-wrap justify-center items-center gap-12">
        {/* Shrenik Mamidala */}
        <div className="team-member text-center">
          <img
            src={shrenikImg}
            alt="Shrenik Mamidala"
            className="w-40 h-40 rounded-full object-cover mx-auto border-4"
            style={{ borderColor: '#e7d4b4', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}
          />
          <h3 className="text-xl mt-4">Shrenik Mamidala</h3>
          <p>CEO</p>
        </div>
        {/* Sevaan Puri */}
        <div className="team-member text-center">
          <img
            src={sevaanImg}
            alt="Sevaan Puri"
            className="w-40 h-40 rounded-full object-cover mx-auto border-4"
            style={{ borderColor: '#e7d4b4', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}
          />
          <h3 className="text-xl mt-4">Sevaan Puri</h3>
          <p>COO</p>
        </div>
        {/* Madhan Mohan */}
        <div className="team-member text-center">
          <img
            src={madhanImg}
            alt="Madhan Mohan"
            className="w-40 h-40 rounded-full object-cover mx-auto border-4"
            style={{ borderColor: '#e7d4b4', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}
          />
          <h3 className="text-xl mt-4">Madhan Mohan</h3>
          <p>CTO</p>
        </div>
        {/* Sumanth Kota */}
        <div className="team-member text-center">
          <img
            src={sumanthImg}
            alt="Sumanth Kota"
            className="w-40 h-40 rounded-full object-cover mx-auto border-4"
            style={{ borderColor: '#e7d4b4', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}
          />
          <h3 className="text-xl mt-4">Sumanth Kota</h3>
          <p>Founding Engineer</p>
        </div>
      </div>
    </section>
  );
};

export default Team;
